/**
 * ログイン時のエラーメッセージを生成する
 * @param error
 * @returns
 */
export function authApiCatch(error: Error): string {
    switch (error.message) {
        case "User does not exist.":
        case "Username/client id combination not found.":
            return "ユーザーが存在しません";
        case "Incorrect username or password.":
            return "メールアドレスまたはパスワードが正しくありません";
        case "Password attempts exceeded":
            return "パスワード試行回数を超えました";
            break;
        case "Invalid verification code provided, please try again.":
            return "無効な確認コードが提供されました";
        case "Attempt limit exceeded, please try after some time.":
            return "試行回数を超えました。数分後にもう一度やり直してください";
            break;
        case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
        case "Password does not conform to policy: Password not long enough":
            return "パスワードが短すぎます";
        case "An account with the given email already exists.":
            return "登録済みのメールアドレスは使用できません";
        case "User is not confirmed.":
            return "メールアドレスの確認が完了していません";
        case "PreSignUp failed with error Invalid email domain.":
            return "許可されていないドメインのメールアドレスは使用できません";
        case "Invalid email address format.":
            return "メールアドレスの形式が正しくありません";
        case "Password did not conform with policy: Password must have lowercase characters":
            return "パスワードには小文字を含める必要があります";
        case "Password did not conform with policy: Password must have uppercase characters":
            return "パスワードには大文字を含める必要があります";
        case "Password did not conform with policy: Password must have numeric characters":
            return "パスワードには数字を含める必要があります";
        case "PreSignUp failed with error Invalid invitation code.":
            return "不正な招待コードです";
        default:
            return error.message;
    }
}
